//colours
$green: #a7d981;
$blue: #395aa6;
$darkBlue: #253141;
$orange: #eb5d48;
$red: #d91418;
$darkGrey: #2C353F;
$white: #ffffff;
$yellow: #FCC673;

//fonts
@font-face {
    font-family: FSRufusLight;
    src:
	    url("../fonts/FSRufus-Light.woff") format("woff"),
    	url("../fonts/FSRufus-Light.otf") format("opentype");
}

@font-face {
    font-family: FSRufus;
    src:
    	url("../fonts/FSRufus-Regular.woff") format("woff"),
    	url("../fonts/FSRufus-Regular.otf") format("opentype");
}

@font-face {
    font-family: FSRufusBold;
    src:
    	url("../fonts/FSRufus-Bold.woff") format("woff"),
    	url("../fonts/FSRufus-Bold.otf") format("opentype");
}

.rufusLight{font-family: "FSRufusLight", sans-serif;}
.rufus{font-family: "FSRufus", sans-serif;}
.rufusBold{font-family: "FSRufusBold", sans-serif; font-weight: normal;}

@font-face {
    font-family: TheSansLight;
    src:
    	url("../fonts/TheSans-Light.woff") format("woff"),
    	url("../fonts/TheSans-Light.otf") format("opentype");
}

@font-face {
    font-family: TheSansSemiBold;
    src:
    	url("../fonts/TheSans-SemiBold.woff") format("woff"),
    	url("../fonts/TheSans-SemiBold.otf") format("opentype");
}

.light{font-family: "TheSansLight", sans-serif;}
.bold{font-family: "TheSansSemiBold", sans-serif; font-weight: normal;}

@font-face {
    font-family: xmas;
    src:
    	url("../fonts/xmas.woff") format('woff'),
    	url("../fonts/xmas.ttf") format("truetype");
}

.xmas{font-family: "xmas", serif;}

//defaults
*{
	box-sizing: border-box;
	//touch-action: none;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}

html,
body,
#root,
.outerWrap,
.wrapper{
    min-height: 100% !important;
    height: 100%;
}

body{
	background: $white;
	color: $white;
  overflow: hidden;
  @extend .light;
}

.react-swipeable-view-container{
	will-change: unset !important;
	transform: none !important;
}

h1,h2,h3,h4,h5,h6{
	margin-top: 0;
	line-height: 1.3;
	@extend .rufusBold;
	color: $white;
}

p, ul{
	font-size: 16px;
	margin-top: 0;
	line-height: 1.4;

	strong{
		@extend .bold;
	}
}

a{
	text-decoration: none;
	transition: all 0.3s ease-in-out;
}

img{
	max-width: 100%;
}

.video-responsive{
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	height: 0;
	margin-bottom: 2rem;
}
.video-responsive iframe{
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	position: absolute;
}

//page
.outerWrap{
  position: relative;
}

.wrapper{
	width: 100%;
	height: 100%;
	transform-origin: top left;
	background: $white;

	canvas{
		z-index: 999;
	}

	header{
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 500;
		text-align: center;
		padding: 2rem;

		.waterTech{
			position: absolute;
			top: 20px;
			left: 100px;

			@media only screen and (max-width: 1200px) {
				left: 40px;
				width: 250px;
			}

			@media only screen and (max-height: 900px) and (min-width: 1200px) {
				left: 20px;
				width: 250px;
			}
		}

		.count{
			background: $white;
			color: $darkBlue;
			padding: 1rem;
			text-align: right;
			border-top-right-radius: 20px;
			border-bottom-left-radius: 20px;
			display: flex;

			@media only screen and (max-width: 767px) {
				margin-top: 1.5rem;
			}

			h4{
				color: $darkBlue;
				font-size: 1rem;
				margin: 0 .5rem;
				@extend .bold;

				@media only screen and (max-width: 767px) {
					font-size: .6rem;
				}
			}

			.number{
				background: #6F84A1;
				padding: .1rem .4rem;
				color: $white;
			}
		}
	}

	.count{
		position: fixed;
		top: 40px;
		right: 40px;
	}

	.page{
	  position: fixed;
	  top: 20px;
	  left: 20px;
	  bottom: 20px;
	  right: 20px;
	  z-index: 400;
	  overflow: hidden;
	  -webkit-overflow-scrolling: touch;
	  transition: transform 1s ease-in-out;
	  display: -ms-flexbox;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-top-right-radius: 30px;
		border-bottom-left-radius: 30px;

		@media only screen and (max-width: 1200px){
	  	top: 20px;
			left: 20px;
			bottom: 20px;
			right: 20px;
			border-top-right-radius: 20px;
			border-bottom-left-radius: 20px;
			overflow-y: auto;
			padding: 4rem 0;
		}

		@media only screen and (max-height: 900px) and (min-width: 1200px) {
	  	top: 20px;
			left: 20px;
			bottom: 20px;
			right: 20px;
			border-top-right-radius: 20px;
			border-bottom-left-radius: 20px;
			overflow-y: auto;
			padding: 4rem 0;
		}

		@media only screen and (max-width: 1023px) {
			padding: 4rem 0 4rem 0;
		}

		@media only screen and (max-width: 767px) {
			padding: 4rem 0 4rem 0;
			justify-content: flex-start;
		}

		.row{
			display: -ms-flexbox;
			display: flex;
			padding: 0;
			margin: 0;
			width: 100%;
			position: relative;

			@media only screen and (max-width: 1023px) {
				flex-wrap: wrap;
			}

			&.align-middle{
				align-items: center;
			}

			&.justify-center{
				justify-content: center;
			}

			&.justify-between{
				justify-content: space-between;
			}

			&.align-bottom{
				align-items: flex-end;
			}

			&.collapse{
				>.columns{
					padding: 0;
				}
			}

			&.medium-pad{
				@media only screen and (max-width: 1200px) {
					padding: 0 1rem;
				}

				@media only screen and (max-width: 767px) {
					padding: 0 1rem;
				}
			}

			.columns{
				padding: 0 4rem;
				position: relative;
				//background: linear-gradient(to right, aqua, red);

				@media only screen and (max-width: 767px) {
					padding: 0 2rem;
				}

				&.text-center{
					text-align: center;
				}

				&.column-block{
					margin-bottom: 2rem;
				}

				&.small{
					flex: 1;
				}

				&.large{
					flex: 1 auto;
					max-width: 1200px;
				}

				&.large-5{
					flex-basis: 35%;

					@media only screen and (max-width: 1280px) {
						flex-basis: 50%;
					}

					@media only screen and (max-width: 1024px) {
						flex-basis: 100%;
					}

					@media only screen and (max-width: 767px) {
						flex-basis: 100%;
					}
				}

				&.large-7{
					flex-basis: 60%;

					@media only screen and (max-width: 1280px) {
						flex-basis: 50%;
					}

					@media only screen and (max-width: 1024px) {
						flex-basis: 100%;
					}

					@media only screen and (max-width: 767px) {
						flex-basis: 100%;
					}
				}

				&.large-10{
					flex-basis: 83.333333334%;

					@media only screen and (max-width: 1023px) {
						flex-basis: 100%;
					}
				}

				&.medium-6{
					@media only screen and (max-width: 1400px) {
						flex-basis: 50%;
					}
					@media only screen and (max-height: 900px) and (min-width: 1200px) {
						flex-basis: 50%;
					}

					@media only screen and (max-width: 1023px) {
						flex-basis: 100%;
					}
				}

				&.medium-8{
					@media only screen and (max-width: 1400px) {
						flex-basis: 66.6666666%;
					}

					@media only screen and (max-width: 1023px) {
						flex-basis: 100%;
					}
				}

				&.auto{
					flex: 1 1 0px;

					@media only screen and (max-width: 767px) {
						width: 100%;
					}
				}

				&.shrink{
					flex: 0 0 auto;

					@media only screen and (max-width: 767px) {
						width: 100%;
					}
				}

				&.left{
					aside{
						opacity: 0;
					}
				}

				&.hide-for-large{
					@media only screen and (max-width: 1600px) {
						display: none;
					}
				}

				&.hide-for-medium{
					@media only screen and (max-width: 1024px) {
						display: none;
					}
				}
			}
		}

		.button{
			background: $yellow;
			color: $darkGrey;
			padding: 1rem 2rem;
			border-top-right-radius: 10px;
			border-bottom-left-radius: 10px;
			display: inline-block;
			@extend .bold;
			text-transform: uppercase;
			cursor: pointer;

			&:hover{
				background: $red;
				color: $white;
			}
		}

		.content{
			width: 100%;
			position: relative;

			h1{
				@extend .xmas;
				color: $darkBlue;
				font-size: 120px;
				line-height: 1;
				margin: 0 0 .5rem 0;
				@extend .animated, .fadeInDown;
				animation-delay: .6s;

				@media only screen and (max-width: 1400px) {
					font-size: 100px;
				}

				@media only screen and (max-width: 1024px) {
					font-size: 60px;
				}

				@media only screen and (max-width: 767px) {
					font-size: 50px;
					margin-bottom: 22px;
				}

				@media only screen and (max-height: 900px) and (min-width: 1200px) {
					margin-bottom: 1rem;
				}
			}

			h2{
				font-size: 1.4rem;
				color: $darkBlue;
				@extend .animated, .fadeInDown;
				animation-delay: .6s;

				@media only screen and (max-width: 1024px) {
					font-size: 1.2rem;
				}

				@media only screen and (max-width: 767px) {
					font-size: 1rem;
				}
			}

			.box{
				background: $darkBlue;
				padding: 1.5rem;
				border-top-right-radius: 20px;
				border-bottom-left-radius: 20px;
				margin-bottom: 20px;
				width: 85%;
				@extend .animated, .fadeInDown;
				animation-delay: .2s;

				@media only screen and (max-width: 1024px) {
					width: 100%;
				}

				@media only screen and (max-width: 767px) {
					padding: 1rem;
				}

				p{
					font-size: 1.2rem;

					@media only screen and (max-width: 767px) {
						font-size: 1rem;
					}

					strong{
						color: $yellow;
					}
				}
			}

			.button{
				@extend .animated, .fadeInDown;

				@media only screen and (max-width: 1024px) {
					margin-bottom: 22px;
				}
			}

			.video-responsive{
				@media only screen and (max-width: 1023px) {
					margin-bottom: 2rem;
				}
			}
		}

		&#pageHome{
			background-image: url('../img/puzzle@2x.jpg');
			background-size: cover;
			background-position: center center;
			justify-content: flex-start;
			padding-top: 10rem;

			@media only screen and (max-height: 700px) and (min-width: 1200px) {
				padding-top: 2rem;
			}

			@media only screen and (max-width: 1024px) {
				padding-top: 6rem;
			}

			@media only screen and (max-width: 767px) {
				background-image: url('../img/puzzleMob@2x.jpg');
				padding-top: 8rem;
			}
		}

		&#pageVideo{
			background: $darkBlue;
			justify-content: center;

			.columns{
				@media only screen and (max-height: 950px) and (min-width: 767px) {
					max-width: 100vh;
				}
			}

		}


		&.question{
			background: $darkBlue;

			.columns{
				padding: 0 6rem;

				@media only screen and (max-width: 1024px) {
					padding: 0 4rem;
				}

				@media only screen and (max-width: 767px) {
					padding: 0 2rem;
				}
			}

			.ball{
				margin-right: 1rem;
				@extend .animated, .fadeInDown;
				animation-delay: .2s;

				@media only screen and (max-width: 767px) {
					width: 50px;
					margin-top: 3rem;
				}
			}

			h1{
				font-size: 100px;
				margin-bottom: 0;
				color: $white;
				animation-delay: .4s;

				@media only screen and (max-width: 1023px) {
					font-size: 80px;
				}

				@media only screen and (max-width: 767px) {
					font-size: 50px;
				}
			}

			p{
				font-size: 1.7rem;
				margin-bottom: 2rem;
				@extend .animated, .fadeInDown;

				@media only screen and (max-width: 1023px) {
					font-size: 1.5rem;
				}

				@media only screen and (max-width: 767px) {
					font-size: 1rem;
				}

				strong{
					color: $yellow;
				}

				a{
					color: $yellow;

					&:hover{
						color: $white;
					}
				}
			}

			input{
				background: $darkBlue;
				border: none;
				border-bottom: 2px solid $yellow;
				outline: none;
				color: $white;
				text-align: center;
				padding-bottom: .2rem;

				&[name="q1p1"]{
					width: 230px;

					@media only screen and (max-width: 767px) {
						width: 150px
					}
				}

				&[name="q2p1"]{
					width: 360px;

					@media only screen and (max-width: 767px) {
						width: 220px
					}
				}

				&[name="q3p1"]{
					width: 260px;

					@media only screen and (max-width: 767px) {
						width: 160px
					}
				}
			}

			.buttons{

				img{
					@extend .animated, .fadeInUp;

					@media only screen and (max-width: 767px) {
						width: 80px;
					}
				}
			}
		}

		&#pageComplete{
			h1{
				font-size: 200px;
				margin-bottom: 2rem;

				@media only screen and (max-width: 1023px) {
					font-size: 120px;
				}

				@media only screen and (max-width: 767px) {
					font-size: 70px;
					margin-top: 3rem;
				}
			}

			h2{
				color: $white;
				margin-bottom: 2rem;
			}

			button{
				border: none;
				background: $red;
				color: $white;
			}
		}

		&#pagePicture{
			background-image: url('../img/final@2x.jpg');
			background-size: cover;
			background-position: center center;
			justify-content: flex-start;
			padding-top: 10rem;

			@media only screen and (max-height: 700px) and (min-width: 1200px) {
				padding-top: 2rem;
			}

			@media only screen and (max-width: 1024px) {
				padding-top: 6rem;
			}

			@media only screen and (max-width: 767px) {
				background-image: url('../img/final@2x.jpg');
			}

			h1{
				font-size: 150px;
				color: $darkBlue;

				@media only screen and (max-width: 1023px) {
					font-size: 120px;
				}

				@media only screen and (max-width: 767px) {
					font-size: 60px;
					margin-top: 3rem;
				}
			}

			button{
				border: none;
				background: $red;
				color: $white;
				margin-top: 2rem;
				display: block;
			}
		}
	}

	footer{
		position: absolute;
		width: 100%;
		bottom: 0;
		z-index: 500;

		.sepsisLogo{
			position: absolute;
			bottom: 40px;
			left: 40px;
			width: 120px;

			@media only screen and (max-width: 767px) {
				width: 70px;
			}
		}

		.veoliaLogo{
			position: absolute;
			bottom: 19px;
			right: 100px;

			@media only screen and (max-width: 1200px) {
				width: 150px;
				right: 40px;
			}

			@media only screen and (max-height: 900px) and (min-width: 1200px) {
				width: 150px;
				right: 20px;
			}
		}
	}
}

// page transitions

.page-enter {
  opacity: 1;
}

.page-exit{
	opacity: 0;
}

.page-enter-active {
  @extend .animated, .fadeIn;
  animation-duration: 1s;
}

.page-exit {
  @extend .animated, .fadeOut;
  animation-duration: 0.3s;
}
